<template>
	<div class="">
		<div class="container-postura-specimen-tree header-table-tree">
			<p class="card title-text">Padrillos</p>
			<p class="card title-text">Madrillas</p>
		</div>
		<div v-for="(postura, key) in items" :key="key">
			<div class="container-postura-specimen-tree" :key="key">
				<div class="container-padrillo">
					<CardSpecimenTree :specimen="postura.padrillo" />
				</div>

				<div class="container-scroll">
					<div class="container-scroll-madrillas">
						<div class="card-container-madrillas">
							<CardSpecimenTree
								v-for="(madrilla, k) in postura.madrillas"
								:specimen="madrilla"
								:key="k"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import posturasService from "@/services/posturas.service"
import CardSpecimenTree from "./CardSpecimenTree.vue"
import { mapState } from "vuex"

export default {
	name: "ComponentTreePosturas",
	components: {
		CardSpecimenTree,
	},
	props: {
		year: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			items: [],
		}
	},
	computed: { ...mapState("encastePosturas", ["start_date", "end_date"]) },
	async mounted() {
		this.init()
	},
	methods: {
		async init() {
			this.isPreloading()
			this.items = await posturasService.getTreePosturas({ start_date: this.start_date, end_date: this.end_date })
			console.log(this.items)
			this.isPreloading(false)
		},
	},
	watch: {
		start_date() {
			this.init()
		},
	},
}
</script>

<style lang="scss">
.container-postura-specimen-tree {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1rem;

	@media screen and (min-width: 500px) {
		grid-template-columns: 1fr 6fr;
		gap: 2rem;
	}

	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 8fr;
		gap: 2rem;
	}

	@media screen and (min-width: 1000px) {
		grid-template-columns: 1fr 8fr;
		gap: 4rem;
	}

	.container-padrillo {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		.card-specimen {
			width: 160px;

			@media screen and (min-width: 1000px) {
				width: 180px;
			}
		}
	}

	.container-scroll {
		overflow-x: scroll;

		.container-scroll-madrillas {
			display: flex !important;
			align-items: flex-start !important;
			min-width: 800px;

			// overflow-x: scroll;
			.card-container-madrillas {
				display: flex !important;
			}

			.card-specimen {
				width: 160px;

				@media screen and (min-width: 1000px) {
					width: 180px;
				}
			}
		}
	}
}

.container-scroll::-webkit-scrollbar {
	height: 2px !important;
	background: #d5d1fb !important;
}

.container-scroll::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 2px !important;
}

.header-table-tree {
	margin-bottom: 1rem;
	position: sticky;
	top: 5.9rem;
	z-index: 100;

	p {
		padding: 1rem 2rem;
		margin-bottom: 0;
		text-align: center;
	}
}

.title-text {
	font-weight: bolder;
	font-size: 14px;

	@media screen and (min-width: 600px) {
		font-size: 16px;
	}
}
</style>
