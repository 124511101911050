<template>
  <div class="card">
    <div class="table-responsive">

      <LotesList
        :postura_id="postura_id"  
      />

    </div>
  </div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import LotesList from "../lotes/LotesList.vue";

export default {
  name: "PosturasListViewById",
  props: {
    postura_id: {
      type: Number,
      required: null,
    },
  },
  components: {
    SpecimenPlate,
    LotesList
  },
  data() {
    return {
      headTable: [
        { text: "CODIGO", value: "codigo", visible: true },
        {
          text: "REG",
          value: "reg",
          visible: true,
          image: "/lotes/lote-grid-1.svg",
        },
        {
          text: "ING",
          value: "ing",
          visible: true,
          image: "/lotes/lote-grid-2.svg",
        },
        {
          text: "INF",
          value: "inf",
          visible: true,
          image: "/lotes/lote-grid-3.svg",
        },
        {
          text: "QUE",
          value: "que",
          visible: true,
          image: "/lotes/lote-grid-4.svg",
        },
        {
          text: "NAC",
          value: "nac",
          visible: true,
          image: "/lotes/lote-grid-5.svg",
        },
        {
          text: "NO N",
          value: "no_n",
          visible: true,
          image: "/lotes/lote-grid-6.svg",
        },
        {
          text: "VIV",
          value: "viv",
          visible: true,
          image: "/lotes/lote-grid-7.svg",
        },
        {
          text: "MUE",
          value: "mue",
          visible: true,
          image: "/lotes/lote-grid-8.svg",
        },
        { text: "CREADO POR", value: "creado_por", visible: true },
      ],
      posturas: [
        {
          madrilla: {
            alias: "CHAMA",
            id: 1,
            plate: "343",
            image: null,
            thumb: null,
          },
          postura: {
            code: "P2200166",
            id: 1,
            status: 1,
          },
          user: {
            name: "JUAN PEREZ",
            created_at: "2020-01-01",
          },
          eggs: {
            reg: 0,
            ing: 0,
            inf: 0,
            que: 0,
          },
          chicks: {
            nac: 0,
            no_n: 0,
            viv: 0,
            mue: 0,
          },
          lote: {
            code: "LOTE0001",
            id: 1,
          },
        },
        {
          madrilla: {
            alias: "CHAMA",
            id: 1,
            plate: "343",
            image: null,
            thumb: null,
          },
          postura: {
            code: "P2200166",
            id: 1,
            status: 1,
          },
          user: {
            name: "JUAN PEREZ",
            created_at: "2020-01-01",
          },
          eggs: {
            reg: 0,
            ing: 0,
            inf: 0,
            que: 0,
          },
          chicks: {
            nac: 0,
            no_n: 0,
            viv: 0,
            mue: 0,
          },
          lote: {
            code: "LOTE0001",
            id: 1,
          },
        },
        {
          madrilla: {
            alias: "CHAMA",
            id: 1,
            plate: "343",
            image: null,
            thumb: null,
          },
          postura: {
            code: "P2200166",
            id: 1,
            status: 1,
          },
          user: {
            name: "JUAN PEREZ",
            created_at: "2020-01-01",
          },
          eggs: {
            reg: 0,
            ing: 0,
            inf: 0,
            que: 0,
          },
          chicks: {
            nac: 0,
            no_n: 0,
            viv: 0,
            mue: 0,
          },
          lote: {
            code: "LOTE0001",
            id: 1,
          },
        },
        {
          madrilla: {
            alias: "CHAMA",
            id: 1,
            plate: "343",
            image: null,
            thumb: null,
          },
          postura: {
            code: "P2200166",
            id: 1,
            status: 1,
          },
          user: {
            name: "JUAN PEREZ",
            created_at: "2020-01-01",
          },
          eggs: {
            reg: 0,
            ing: 0,
            inf: 0,
            que: 0,
          },
          chicks: {
            nac: 0,
            no_n: 0,
            viv: 0,
            mue: 0,
          },
          lote: {
            code: "LOTE0001",
            id: 1,
          },
        },
      ],
      modalViewByPostura: {
        title: "Detalle de postura",
        visible: false,
        data: null,
      },
    };
  },
  computed: {},
  mounted() {
    console.log("postura_id", this.postura_id);
  },
  methods: {},
};
</script>

<style lang="scss" scope>
.box-primary {
  background: #7367f0 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  margin: 2rem 0 !important;
  height: 40px;
  // display: flex;
  // align-items: center;
  padding-top: 0.7rem !important;
}
.table-responsive {
  margin-bottom: 0;
}
.icon-head-posturas {
  margin-right: 0.2rem;
}
</style>
