<template>
	<b-modal
		ref="modal-list-register-eggs"
		@hidden="$emit('hidden')"
		:title="title"
		no-close-on-backdrop
		cancel-title="Cancelar"
		size="md"
	>
		<b-table-simple class="w-100" hover responsive sticky-header="calc(100vh - 200px)">
			<b-thead>
				<b-tr class="text-center text-uppercase">
					<b-th>Fecha Registro</b-th>
					<b-th>Creado por</b-th>
					<b-th>Cantidad</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr v-for="(input, index) in inputs" :key="`${index}-input`" class="text-center">
					<!-- <b-td>
						{{ input.quantity }}
					</b-td> -->
					<b-td>
						{{ input.register_date | myGlobalDayShort }}
					</b-td>
					<b-td>
						{{ input.updated_by }}
						<br />
						{{ input.created_at | myGlobalDayShort }}
					</b-td>
					<b-td>
						{{ input.quantity }}
					</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>

		<template #modal-footer>
			<div class="w-100 px-2 py-0">
				<b-table-simple>
					<b-tbody>
						<b-tr class="text-center text-uppercase font-weight-bolder bg-primary text-white">
							<b-td class="border-0" style="width: 120px"></b-td>
							<b-td class="border-0">Total</b-td>
							<b-td class="border-0">
								<div>{{ totalInputs }}</div>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js";
import eggService from "@/services/egg.service";
export default {
	name: "ListRegisterEggs",
	mixins: [modalMixin],
	props: {
		dataEggs: {
			type: Object,
			required: true,
		},
	},
	async mounted() {
		this.isPreloading();
		await this.getInputs();
		this.toggleModal("modal-list-register-eggs");
		this.isPreloading(false);
	},
	computed: {
		title() {
			return (
				this.dataEggs.postura.code +
				" / " +
				(this.dataEggs.madrilla.alias ? this.dataEggs.madrilla.alias : "") +
				` (${this.dataEggs.madrilla.plate})`
			);
		},
		totalInputs() {
			return this.inputs.reduce((a, b) => {
				return parseFloat(a + b.quantity * 1);
			}, 0);
		},
	},
	data() {
		return {
			inputs: [],
			post_code: null,
		};
	},
	methods: {
		async getInputs() {
			const { postura } = this.dataEggs;
			const data = await eggService.getEggsByPostura({ postura_id: postura.id });
			[this.inputs] = data.registros.map((p) => p.egg_inputs);
		},
	},
};
</script>

<style lang="scss" scoped></style>
