<template>
	<div>
		<div>
			<div class="d-flex w-100">
				<slot name="title"></slot>
			</div>
			<div class="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
				<div class="container-subtitle d-flex align-items-center border-white text-white bg-info rounded">
					<slot name="subtitle"></slot>
				</div>
				<div :style="{ padding: '0' }" class="container-options border-white bg-white">
					<slot name="options"></slot>
				</div>
			</div>

			<slot name="table"></slot>

			<slot name="btns"></slot>

			<slot name="modals"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "RevisionLotesSlot",
};
</script>

<style lang="scss" scoped>
.container-subtitle-options {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	// background: red;
	@media screen and (min-width: 1000px) {
		flex-direction: row;
		justify-content: space-between;
	}
	.container-options {
		width: 100%;
		margin: 0.5rem 0;
		display: flex;
		justify-content: flex-end;
		text-align: right;
		// background: red;
		@media screen and (min-width: 1000px) {
			width: auto;
		}
	}
}
.container-title {
	width: 100% !important;
	background: red;
	// height: 400px;
	@media screen and (min-width: 600px) {
		width: 60% !important;
	}
	@media screen and (min-width: 1000px) {
		width: auto !important;
	}
	@media screen and (min-width: 1400px) {
		// width: 20% !important;
	}
	@media screen and (min-width: 1800px) {
		// width: 30% !important;
	}
}

.container-subtitle {
	padding: 1rem;
}
// .container-subtitle{
//     width: 100% !important;
//     background: red;
//     // height: 400px;
//     @media screen and (min-width: 600px) {
//         width: 80% !important;
//     }
//     @media screen and (min-width: 1000px) {
//         width: 60% !important;
//     }
//     @media screen and (min-width: 1400px) {
//         width: 50% !important;
//     }
//     @media screen and (min-width: 1800px) {
//         width: 30% !important;
//     }
// }
</style>
