<template>
	<b-modal
		:title="`Motivos de muerte / ${getTitle}`"
		size="lg"
		v-model="isActive"
		centered
		hide-footer
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<h5 class="text-uppercase">
				Total de muertos:
				<span class="text-danger font-bold">{{ info.deads_lote }}</span>
			</h5>
			<VueApexCharts type="pie" height="400" :options="chartOpts" :series="chartData" />
		</b-overlay>
	</b-modal>
</template>

<script>
import lotesService from "@/services/lotes.service"
import moment from "moment"

import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: {
		show: Boolean,
		info: Object,
		isGeneral: { type: Boolean, required: false, default: false },
		season: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		chartData: [1],
		chartOpts: {
			labels: ["base"],
		},
		totalDeads: 0,
	}),
	computed: {
		getTitle() {
			if (!this.isGeneral) return `Lote ${this.info.code || "---"}`

			const tempYear = moment(this.season.start).year()
			return `Lotes de temporada ${tempYear}`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getReasons() {
			this.isLoading = true

			if (this.isGeneral) {
				await this.getReasonsGeneral()
				return
			}
			const { data } = await lotesService.getLoteDeadsReason({ id: this.info.id })
			const qtys = []
			const labels = []
			data.sort((a, b) => Number(b.qty) - Number(a.qty))
			data.forEach((data) => {
				qtys.push(Number(data.qty))
				labels.push(String(data.reason).toUpperCase())
			})
			this.chartData = qtys
			this.chartOpts = {
				labels,
				dataLabels: {
					formatter: function (value, { seriesIndex }) {
						return `${Number(value).toFixed(1)}%=${qtys[seriesIndex]}`
					},
					style: { colors: ["#fff"] },
				},
				legend: {
					formatter: function (seriesName, opts) {
						return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]]
					},
				},
			}
			this.isLoading = false
		},
		async getReasonsGeneral() {
			try {
				const { data } = await lotesService.getLoteDeadsReasonGeneral({
					start_date: this.season.start,
					end_date: this.season.end,
				})
				const qtys = []
				const labels = []
				data.sort((a, b) => Number(b.qty) - Number(a.qty))
				data.forEach((data) => {
					qtys.push(Number(data.qty))
					labels.push(String(data.reason).toUpperCase())
				})
				this.chartData = qtys
				this.chartOpts = {
					labels,
					dataLabels: {
						formatter: function (value, { seriesIndex }) {
							return `${Number(value).toFixed(1)}%=${qtys[seriesIndex]}`
						},
						style: { colors: ["#fff"] },
					},
					legend: {
						formatter: function (seriesName, opts) {
							return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]]
						},
					},
				}
			} catch (error) {
				console.log("errorr: ", error)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getReasons()
			}
		},
	},
}
</script>
