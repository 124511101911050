<template>
	<b-modal
		:title="`Hijos vivos / Lote ${info.code || '---'}`"
		size="lg"
		v-model="isActive"
		centered
		hide-footer
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<b-tabs v-model="tab" lazy @input="loadData">
				<b-tab :disabled="!counters.pata">
					<template #title>
						Cintillos en Pata
						<b-badge class="ml-1" variant="primary" v-if="counters.pata">{{ counters.pata }}</b-badge>
					</template>
					<b-table-simple sticky-header="60vh" striped>
						<b-thead class="text-center">
							<b-tr>
								<b-th>Padrillo</b-th>
								<b-th>Madrilla</b-th>
								<b-th>Cant. Pollos</b-th>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<b-tr v-for="(row, idx) in patas" :key="idx">
								<b-td>
									<SpecimenPlate :specimen="row.padrillo" />
								</b-td>
								<b-td>
									<SpecimenPlate :specimen="row.madrilla" />
								</b-td>
								<b-td>{{ row.quantity }}</b-td>
							</b-tr>
							<b-tr v-if="patas.length == 0">
								<b-td class="text-center" colspan="3">No hay registros para mostrar</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</b-tab>
				<b-tab :disabled="!counters.ala">
					<template #title>
						Cintillos en Ala
						<b-badge class="ml-1" variant="primary" v-if="counters.ala">{{ counters.ala }}</b-badge>
					</template>
					<b-table-simple sticky-header="60vh" striped>
						<b-thead class="text-center">
							<b-tr>
								<b-th>Padrillo</b-th>
								<b-th>Madrilla</b-th>
								<b-th>Pollos</b-th>
							</b-tr>
							<b-tr v-if="alas.length < 0">
								<b-td class="text-center" colspan="3">No hay registros para mostrar</b-td>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<b-tr v-for="(row, idx) in alas" :key="idx">
								<b-td>
									<SpecimenPlate :specimen="row.padrillo" />
								</b-td>
								<b-td>
									<SpecimenPlate :specimen="row.madrilla" />
								</b-td>
								<b-td>
									<div class="d-flex justify-content-center gap-x-2 flex-wrap">
										<div v-for="chick in row.chicks" :key="chick.cintillo">
											<b-badge variant="secondary" v-if="chick.is_specimen">
												{{ chick.cintillo }}
											</b-badge>
											<b-badge
												:variant="[2, 5].includes(chick.status_id) ? 'primary' : 'warning'"
												v-else
											>
												{{ chick.cintillo }}
											</b-badge>
										</div>
									</div>
								</b-td>
							</b-tr>
							<b-tr v-if="alas.length == 0">
								<b-td class="text-center" colspan="3">No hay registros para mostrar</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
					<hr />
					<h5 class="text-primary">Leyenda</h5>
					<div class="alive-chicks">
						<div>
							<div class="box bg-primary"></div>
							<div class="text">Activo</div>
						</div>
						<div>
							<div class="box bg-secondary"></div>
							<div class="text">Con placa</div>
						</div>
						<div>
							<div class="box bg-warning"></div>
							<div class="text">Sin confirmar</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</b-overlay>
	</b-modal>
</template>

<script>
import lotesService from "@/services/lotes.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: { show: Boolean, info: Object },
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: 0,
		patas: [],
		alas: [],
		counters: { pata: 1, ala: 1 },
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.tab = 0
			this.patas = []
			this.alas = []
			this.counters = { pata: 1, ala: 1 }
			this.$emit("closing")
		},
		async getCounters() {
			this.isLoading = true
			const { data } = await lotesService.getLoteAlivesCounter({ id: this.info.id })
			this.counters = { pata: data[0].count_cp, ala: data[0].count_chicks }
			this.isLoading = false
			this.loadData()
		},
		async getCintillosPata() {
			this.isLoading = true
			const { data } = await lotesService.getLoteAlivesCp({ id: this.info.id })
			this.patas = data
			this.isLoading = false
		},
		async getCintillosAla() {
			this.isLoading = true
			const { data } = await lotesService.getLoteAlivesCa({ id: this.info.id })
			this.alas = data
			this.isLoading = false
		},
		loadData() {
			if (this.tab == 0) this.getCintillosPata()
			else this.getCintillosAla()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getCounters()
			}
		},
	},
}
</script>
<style>
.alive-chicks .box {
	float: left;
	width: 12px;
	height: 12px;
	margin-right: 10px; /* Espacio entre el div y el texto */
	margin-top: 5px;
	border-radius: 50%;
}
.alive-chicks .text {
	margin-top: 5px !important;
}
</style>
