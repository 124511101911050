<template>
	<b-modal centered no-close-on-backdrop hide-footer :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<h2 class="text-center mb-2">
				Total
				<b-badge variant="primary">
					{{ info.viv }}
				</b-badge>
			</h2>
			<b-row class="text-center">
				<b-col>
					Ejemplares
					<b-badge :variant="counters.specimens > 0 ? 'danger' : 'secondary'">
						{{ counters.specimens }}
					</b-badge>
				</b-col>
				<b-col>
					Cintillos Ala
					<b-badge :variant="counters.alas > 0 ? 'danger' : 'secondary'">
						{{ counters.alas }}
					</b-badge>
				</b-col>
			</b-row>
			<hr />
			<b-table-simple class="text-center" sticky-header="60vh">
				<b-thead>
					<b-tr>
						<b-th>Tipo</b-th>
						<b-th>Hijo</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>{{ row.type }}</b-td>
						<b-td>
							<SpecimenPlate :specimen="row" v-if="row.type == 'EJEMPLAR'" />
							<b-badge variant="primary" v-else>{{ row.correlative }}</b-badge>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="2">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import posturaService from "@/services/posturas.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: { show: Boolean, info: Object },
	data: () => ({
		isActive: false,
		isLoading: false,
		//
		specimens: [],
		alas: [],
		counters: { patas: 0, alas: 0, specimens: 0 },
	}),
	computed: {
		title() {
			return `Hijos Vivos / Postura: ${this.info.code || "---"}`
		},
		rows() {
			const s = this.specimens.map((o) => ({ ...o, type: "EJEMPLAR" }))
			const c = this.alas.map((o) => ({ ...o, type: "CINTILLO ALA" }))
			return [...s, ...c]
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.alas = []
			this.specimens = []
			this.counters = { patas: 0, alas: 0, specimens: 0 }
			this.$emit("closing")
		},
		async getData() {
			this.isLoading = true
			const data = await posturaService.getPosturaChildsAlive({ postura_id: this.info.postura_id })
			this.alas = data.alas
			// cuando hay alas que aun no tienen correlativo asignado, rellenar el array
			const alasDiff = data.counters.alas - data.alas.length
			for (let i = 0; i < alasDiff; i++) {
				this.alas.push({ correlative: "CORRELATIVO SIN ASIGNAR" })
			}
			//
			this.specimens = data.specimens
			this.counters = { patas: data.counters.patas, alas: data.counters.alas, specimens: data.counters.specimens }
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getData()
			}
		},
	},
}
</script>
