<template>
	<div @click="clickToggle" class="container-switch mr-50" :class="[toggle ? 'active' : '']">
		<div class="barra" :class="[toggle ? 'active' : '']"></div>
		<!-- <div class="barra-text">
			<div class="w-50 d-flex justify-content-center align-items-center">
				<p class="mb-0" :class="[toggle ? 'text-light' : 'text-primary']"></p>
			</div>
			<div class="w-50 d-flex justify-content-center align-items-center">
				<p class="mb-0" :class="[toggle ? 'text-primary' : 'text-light']"></p>
			</div>
		</div> -->
	</div>
</template>

<script>
export default{
    name: 'Toggle',
    props: {
        value: {
			type: Boolean,
		},
    },
    data () {
        return {
            toggle: null
        }
    },
	mounted() {
		this.toggle = this.value;
	},
    methods: {
        clickToggle() {
            
            this.toggle = !this.toggle
            this.$emit('input', this.toggle);
        }
    },
    watch: {
		value(val) {
			this.toggle = val;
		},
	},

}
</script>

<style scoped lang="scss">
.container-switch {
	background: #e4e3e3;
	width: 36px;
	height: 20px;
	border-radius: 10px;
	position: relative;
	cursor: pointer;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	.barra {
		position: absolute;
		top: 2px;
		left: 2px;
		width: 16px;
		height: 16px;
		background: #fff;
		border-radius: 34px;
		transition: all 0.3s ease;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		background: #f5f5f5;
	}
	.active{
		left: 18px !important;
		background: #e4e3e3 !important;
	}
}
.active{
	background: #7367f0 !important;
	// .barra{
	// 	left: 16px !important;
	// 	background: red !important;
	// }
}

</style>