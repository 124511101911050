<template>
  <b-avatar
    :variant="stage.variant"
    class="mr-1"
    :text="`${index + 1}`"
    size="35"
    button
    :class="{ 'pointer-events-none': stage.lotes_stage_order == 1 }"
    :disabled="disabledStage"
    @click="$emit('open-stage', stage)"
  />
</template>

<script>
export default {
  name: "Stagelote",
  props: {
    // el actual
    stage: {
      type: Object,
      required: true,
    },
    // todos los demas
    stages: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    disabledStage() {
      let status_previous_stage = false;
      if (this.index > 0) {
        status_previous_stage = [2,4].includes(this.stages[this.index - 1].status_stage_lote_id);
      }
      return status_previous_stage || this.stage.status_stage_lote_id === 1;
    },
  },
};
</script>

<style>
</style>