<template>
	<b-modal centered no-close-on-backdrop size="sm" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-form-group label="Estado">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							label="text"
							:reduce="(opt) => opt.value"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="statusOpts"
							v-model="form.status"
							@input="clearForm"
							:selectable="(opt) => opt.selectable"
						/>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Cliente o beneficiario" v-if="[11, 5, 4, 2].includes(form.status)">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							label="text"
							:reduce="(opt) => opt.value"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="clientOpts"
							v-model="form.client"
						/>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Motivo" v-if="form.is_reason == 1">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							label="text"
							:reduce="(opt) => opt.value"
							:class="{ 'border-danger rounded': errors[0] }"
							:options="diseaseOpts"
							v-model="form.disease"
						/>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Peso" v-if="[9, 10].includes(form.status)">
					<b-row>
						<b-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-input :class="{ 'border-danger ': errors[0] }" v-model="form.weight" />
							</ValidationProvider>
						</b-col>
						<b-col>
							<b-form-input :value="weightToKilo" disabled />
						</b-col>
					</b-row>
				</b-form-group>

				<b-form-group label="Evidencias" v-if="[3].includes(form.status)">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-form-file :class="{ 'border-danger ': errors[0] }" multiple v-model="form.evidence" />
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Precio" v-if="[2].includes(form.status)">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-input-group prepend="$">
							<b-form-input type="number" :class="{ 'border-danger ': errors[0] }" v-model="form.price" />
						</b-input-group>
					</ValidationProvider>
				</b-form-group>
				<b-form-group label="Dias de descanso" v-if="[14].includes(form.status)">
					<v-select
						:getOptionLabel="(o) => String(o)"
						:reduce="(opt) => opt"
						:options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
						v-model="form.restingDays"
					/>
				</b-form-group>
				<b-form-group label="Descripcion" v-if="form.status != 3">
					<ValidationProvider rules="" v-slot="{ errors }">
						<b-form-textarea :class="{ 'border-danger': errors[0] }" v-model="form.description" />
					</ValidationProvider>
				</b-form-group>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="update">Actualizar</b-button>
		</template>
	</b-modal>
</template>

<script>
import moment from "moment"

import specimenService from "@/services/specimens.service"
import utilitiesService from "@/services/utilities.service"
import clientsService from "@/services/clients.service"
import reasonsService from "@/services/reasons.service"
import { getDiseases } from "@/views/brain/specimens/views/diseases/diseases.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		//
		statusOpts: [],
		userOpts: [],
		clientOpts: [],
		diseaseOpts: [],
		deadReasonOpts: [],
		form: {
			status: null,
			client: null,
			responsable: null,
			disease: null,
			weight: null,
			deadReason: null,
			evidence: null,
			price: null,
			description: null,
			date: null,
			restingDays: null,
			is_reason: null,
		},
	}),
	computed: {
		specimen() {
			if (!this.info._parent) return null
			return this.info._parent == "m" ? this.info.madrilla : this.info.padrillo
		},
		title() {
			if (!this.info._parent) return "Cambiar estado"
			return `Cambiar estado de ${this.info._parent == "m" ? "madrilla" : "padrillo"} ${
				this.specimen.alias || this.specimen.plate
			}`
		},
		weightToKilo() {
			if (!this.form.weight) return "0.00"
			return (this.form.weight / 2.20462).toFixed(2)
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.form = {
				status: null,
				client: null,
				responsable: null,
				disease: null,
				weight: null,
				deadReason: null,
				evidence: null,
				price: null,
				description: null,
				restingDays: null,
			}
			this.$emit("closing")
		},
		clearForm() {
			this.form.client = null
			this.form.responsable = null
			this.form.disease = null
			this.form.weight = null
			this.form.deadReason = null
			this.form.evidence = null
			this.form.price = null
			this.form.description = null
			this.form.restingDays = null
			// autoselect responsable
			let match = this.userOpts.find((opt) => opt.value == this.currentUser.id)
			if (match) this.form.responsable = match.value
		},
		getBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = (error) => reject(error)
			})
		},
		async prepareEvidence() {
			let res = []
			for (let idx = 0; idx < this.form.evidence.length; idx++) {
				const file = this.form.evidence[idx]
				let objFile = await this.getBase64(file)
				res.push({
					base: objFile,
					name: file.name,
					size: file.size,
				})
			}
			return res
		},
		async getOpts() {
			this.isLoading = true
			// const resStatus = await specimenService.getAllStatusSpecimens()
			// const resUser = await utilitiesService.getUsersByRole()
			// const resClient = await clientsService.getClients()
			// const resReason = await reasonsService.getByModule(1)
			// const resDisease = await getDiseases()

			const responses = await Promise.all([
				specimenService.getAllStatusSpecimens(this.info._parent == "p" ? 1 : 2),
				utilitiesService.getUsersByRole(),
				clientsService.getClients(),
				// reasonsService.getByModule(1),
				// getDiseases(),
			])
			console.log("response: ", responses)
			this.statusOpts = responses[0]
				.filter((opt) => {
					// si es madrilla no descansa
					if (opt.id == 14 && this.info._parent == "m") return false
					// Si tiene posturas pendientes no puede descansar
					if (opt.id == 14 && this.info.padrillo.p_pending > 0) return false
					return opt.id != this.specimen.status
				})
				.map((opt) => ({
					value: opt.id,
					text: `------ ${opt.description}`,
					in_galpon: opt.in_galpon,
					selectable: true,
					is_reason: opt.is_reason,
				}))

			this.statusOpts.unshift({
				value: null,
				text: "DISPONIBLES",
				selectable: false,
			})

			const indexInactivos = this.statusOpts.findIndex((it) => it.in_galpon == 0)

			// console.log("index", indexInactivos)

			this.statusOpts.splice(indexInactivos, 0, {
				value: null,
				text: "NO DISPONIBLES",
				selectable: false,
			})

			this.userOpts = responses[1].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.clientOpts = responses[2].data.map((opt) => ({ value: opt.id, text: opt.name }))
			// this.diseaseOpts = responses[4].map((opt) => ({ value: opt.id, text: opt.name }))
			// this.deadReasonOpts = responses[3].map((opt) => ({ value: opt.id, text: opt.reason }))
			this.isLoading = false
		},
		async update() {
			if (!(await this.$refs.form.validate())) return

			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se actualizara el estado de ${this.specimen.alias || this.specimen.plate} a ${
					this.statusOpts.find((opt) => opt.value == this.form.status).text
				}`,
			})
			if (!isConfirmed) return
			let myEvidence = []
			if (this.form.status == 3 && this.form.evidence.length) {
				myEvidence = await this.prepareEvidence()
			}
			this.isPreloading()
			try {
				const updatedInfo = {
					category_id: 2,
					check: false,
					new_status_id: this.form.status,
					client_id: this.form.client,
					responsable_id: [6, 13, 9, 12, 10].includes(this.form.status) ? this.currentUser.id : null,
					disease_id: this.form.disease,
					pounds_weight: this.form.weight,
					// reason_death_id: this.form.deadReason,
					reason_death_id: this.form.disease,
					reason_id: this.form.disease,
					evidences: [],
					price: this.form.price,
					description: this.form.description || "Modificado desde posturas",
					date_status: moment(new Date()).format("YYYY-MM-DD"),
					specimen_id: this.specimen.id,
					customFormData: myEvidence,
					weight: this.form.weight ? this.form.weight / 2.2046 : null,
					restingDays: this.form.restingDays,
				}
				switch (this.form.status) {
					case 2:
						updatedInfo.type_ouput = 1
						break
					case 4:
						updatedInfo.type_ouput = 2
						break
					case 5:
						updatedInfo.type_ouput = 3
						break
					case 11:
						updatedInfo.type_ouput = 4
						break
				}
				const body = {
					created_by: this.currentUser.id,
					all_status: [updatedInfo],
					deads: JSON.stringify(myEvidence),
				}
				await specimenService.saveStatusMassively(body)
				this.handleHidden()
				this.showToast("success", "top-right", "Posturas", "SuccessIcon", "Actualizado correctamente.")
				this.$emit("refresh")
			} catch (error) {
				console.log("error-posturas: ", error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getOpts()
			}
		},
		async "form.status"(newValue) {
			const currentstatus = this.statusOpts.find((st) => st.value == newValue)
			this.form.is_reason = null
			this.diseaseOpts = []
			if (currentstatus) {
				this.form.is_reason = currentstatus.is_reason
				if (currentstatus.is_reason == 1) {
					this.isPreloading()
					try {
						const sts = await reasonsService.getByModule(1, newValue)
						this.diseaseOpts = sts.map((opt) => ({ value: opt.id, text: opt.reason }))
						// await this.getReasons({ module_id: 1, status_id: newValue, index: this.index })
					} catch (error) {
						console.log("error-reasons: ", error)
					} finally {
						this.isPreloading(false)
					}
				}
			}
		},
	},
}
</script>
