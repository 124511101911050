<template>
	<b-modal
		ref="modal-register-deads"
		@hidden="$emit('hidden', false)"
		:title="title"
		no-close-on-backdrop
		cancel-title="Cancelar"
		size="lg"
	>
		<b-form-group label="Elegir matar o depurar" v-slot="{ ariaDescribedby }">
			<div class="d-flex">
				<b-form-radio
					class="mr-1 d-flex align-items-center"
					v-model="depuracion_boolean"
					name="some-radios"
					:value="true"
					>Muerto</b-form-radio
				>
				<b-form-radio
					class="mr-1 d-flex align-items-center"
					v-model="depuracion_boolean"
					name="some-radios"
					:value="false"
					>Depurar</b-form-radio
				>
			</div>
		</b-form-group>

		<b-row>
			<b-col lg="12">
				<ValidationObserver ref="form">
					<b-card v-for="(dead, index) in arrayDeads" :key="index">
						<b-row>
							<b-col cols="12" md="6" lg="6" class="d-flex flex-column">
								<!-- CINTILLO EN LA PATA -->
								<!-- <h3 class="mb-0 mr-1">
									<b-badge variant="primary">{{ index + 1 }}</b-badge>
								</h3> -->
								<b-form-group
									class="flex-grow-1 w-100"
									label="Elige un lote"
									label-for="multi"
									v-if="evolutionSpecimen == 1"
								>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="dead.id"
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
											:options="
												lote_id
													? lotesAvailable.filter((it) => it.lote_id == lote_id)
													: lotesAvailable
											"
											:clearable="false"
											label="code"
											:reduce="(option) => option"
											:class="{ 'border-danger rounded': errors[0] }"
											@input="onSelectLoteCode(dead, index)"
											class="select-cintillo"
										>
											<template #selected-option-container="{ option }">
												<div
													class="d-flex justify-content-between align-items-center w-100"
													style="width: 100%"
												>
													<span style="font-size: 12px">{{ option.code }} </span>
													<span v-if="dead.postura">{{ dead.postura.code }}</span>
												</div>
											</template>
											<template v-slot:option="option">
												<div class="d-flex justify-content-between align-items-center">
													<span style="font-size: 12px">
														<small class="text-muted">LOTE: </small>{{ option.code }}
													</span>
												</div>
											</template>
										</v-select>
										<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
									</ValidationProvider>
								</b-form-group>
								<!-- CINTILLO EN ALA -->
								<b-form-group
									class="d-block flex-grow-1 mb-5"
									label="Ingresa el cintillo en el ala"
									label-for="ala"
									v-if="evolutionSpecimen === 2"
								>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<b-form-input
											class="d-none"
											v-model="dead.id"
											placeholder="Enter your name"
										></b-form-input>

										<SelectInfiniteScroll
											api="chick/get-chicks-actives"
											key_json="chicks"
											label="correlative"
											placeholder="Ingrese el cintillo en ala"
											:v_model="dead"
											:error="errors[0]"
										/>
									</ValidationProvider>
								</b-form-group>
							</b-col>
							<template v-if="depuracion_boolean">
								<b-col class="input-select-observation" cols="12" md="6" lg="6">
									<!-- OBSERVACION -->
									<b-form-group id="input-group-1" label="Observación:" label-for="input-1">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<v-select
												v-model="dead.reason_id"
												:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
												:options="arrayReason"
												:clearable="false"
												label="reason"
												:reduce="(option) => option.id"
												:class="{ 'border-danger rounded': errors[0] }"
												@input="
													() => {
														dead.observation = null;
													}
												"
											>
												<template v-slot:option="option">
													<div class="d-flex justify-content-between align-items-center">
														<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
															>{{ option.reason }}
														</span>
														<span v-if="option.parent_id">
															Sub-motivo de {{ option.parent_reason }}
														</span>
													</div>
												</template>
											</v-select>
											<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-form-group>
									<b-form-group label="Otros" label-for="observation" v-if="dead.reason_id === 19">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<b-input-group label-for="observation">
												<b-form-textarea
													id="observation"
													v-model="dead.observation"
													type="text"
													placeholder="Ingrese otra razon"
													:state="errors[0] ? false : null"
												></b-form-textarea>
											</b-input-group>
											<span class="text-danger">{{ errors[0] }}</span>
										</ValidationProvider>
									</b-form-group>
								</b-col>
								<b-col class="d-flex justify-content-center align-items-center">
									<!-- dd -->
								</b-col>
							</template>

							<template v-else>
								<b-col class="input-select-observation" cols="12" lg="6" md="6">
									<!-- {{ dead.observation }} -->
									<b-form-group
										description="Descripcion de la depuracion(opcional)."
										label="Observaciones:"
									>
										<b-form-textarea
											v-model="dead.observation"
											placeholder="Ingrese una observacion"
											rows="3"
											max-rows="6"
										></b-form-textarea>
									</b-form-group>
								</b-col>
							</template>
						</b-row>
						<b-row class="mt-0" >
							<b-col sm="6" v-if="dead.postura && evolutionSpecimen == 1">
								<div  class="d-flex flex-column">
									<label class="small">Cantidad</label>
									<vue-number-input
										v-model="dead.postura.quantity"
										:min="1"
										:max="
											stockPostura(arrayStocks, dead.postura.egg_lote_id) + dead.postura.quantity
										"
										inline
										controls
										style="max-width: 150px; margin-top: 5px"
										class="text-center"
										@change="onUpdate($event, index, dead)"
									/>
								</div>
							</b-col>
							<b-col sm="6" v-if="depuracion_boolean">
								<div
									class="container-evidences mt-50 mt-md-0"
									v-if="dead.postura &&  [1,2].includes(evolutionSpecimen)"
								>
									<div>
										<label class="small">Evidencia</label>
										<b-form-checkbox
											class="mt-25"
											v-model="dead.withEvidence"
											name="check-button"
											switch
										>
										</b-form-checkbox>
									</div>
									<DragAndDropImages
										class="mt-1"
										v-if="dead.withEvidence"
										v-model="dead.evidences"
										:filesArray="dead.evidences"
										:source="`source${index}`"
										:containerDescription="`Seleccionar evidencia`"
										containerPadding="25px"
									/>
								</div>
							</b-col>
						</b-row>
						<feather-icon
							icon="XIcon"
							class="text-danger cursor-pointer"
							@click="deleteDead(dead, index)"
							size="20"
							style="position: absolute; top: 0px; right: 3px"
						/>
					</b-card>
				</ValidationObserver>
			</b-col>
		</b-row>

		<!-- AGREGAR UN REGISTRO DE MUERTO -->
		<b-button variant="gradient-primary" class="btn-icon rounded-circle" id="fixedbutton" @click="addDead">
			<feather-icon icon="PlusIcon" size="18" />
		</b-button>

		<!-- ABRIR LOTES POR COMBINACION -->
		<b-modal
			@hide="hideFooterSelectCintillo"
			v-model="openLotesByCombination"
			size="xl"
			:title="selectedLote.code"
			hide-footer
		>
			<b-table-simple hover responsive bordered sticky-header="calc(100vh - 120px)">
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-1">Postura</b-th>
						<b-th class="p-1">Cintillos</b-th>
						<b-th class="p-1">Madrilla</b-th>
						<b-th class="p-1">Padrillo</b-th>
						<b-th class="p-1">Vivos</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(postura, index) in arrayPosturas" :key="postura.id" class="text-center">
						<b-td>{{ postura.code }}</b-td>
						<b-td>
							<div class="d-flex justify-content-center">
								<CombChickenlegsCintillos
									v-if="postura.cintillos && postura.cintillos.id"
									:postura="{
										chickenleg_wich_leg: postura.cintillos.wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: 1,
									}"
									:chickenleg_wich_leg="1"
									is_in_posturas
								/>
								<CombChickenlegsCintillos
									v-if="postura.cintillos && postura.cintillos.id"
									:postura="{
										chickenleg_wich_leg: postura.cintillos.wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: 1,
									}"
									is_in_posturas
									:chickenleg_wich_leg="2"
								/>
							</div>
						</b-td>
						<b-td>
							<SpecimenPlate
								:specimen="{
									id: postura.madrilla.id,
									plate: postura.madrilla.plate,
									alias: postura.madrilla.alias,
									thumb: postura.madrilla.thumb,
									image: postura.madrilla.image,
								}"
							/>
						</b-td>
						<b-td>
							<SpecimenPlate
								:specimen="{
									id: postura.padrillo.id,
									plate: postura.padrillo.plate,
									alias: postura.padrillo.alias,
									thumb: postura.padrillo.thumb,
									image: postura.padrillo.image,
								}"
							/>
						</b-td>
						<b-td class="" @click="onSelectPostura(postura, index)" data-label="Vivos">
							<h3 class="mb-0 text-primary line" :style="{ textDecoration: 'underline' }">
								{{ postura.qty }}
							</h3>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>

		<template #modal-footer="{ cancel }">
			<b-button @click="cancel()"> Cancelar </b-button>
			<b-button variant="primary" @click="saveDeads()" v-if="!saveLoading"> Guardar </b-button>
			<b-button variant="primary" disabled v-else>
				<b-spinner small />
				Cargando...
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import modalMixin from "@/mixins/modal.js";
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue";
import { setEvidencesBase64 } from "@/helpers/functions";
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos";
import SelectInfiniteScroll from "@/views/amg/deads/components/SelectInfiniteScroll.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import reasonsService from "@/services/reasons.service";
import deadsService from "@/services/deads.service";
import chicksService from "@/services/encaste/chicks.service";

export default {
	name: "RegisterDeads",
	components: {
		DragAndDropImages,
		CombChickenlegsCintillos,
		SelectInfiniteScroll,
		SpecimenPlate,
	},
	mixins: [modalMixin],
	props: {
		// { item: 1, name: "Cintillo pata" },
		// { item: 2, name: "Cintillo ala" },
		// { item: 3, name: "Placa" },
		evolutionSpecimen: {
			type: Number,
			required: true,
		},
		lote_id: {
			type: Number,
			default: null,
		},
		postura_id: {
			type: Number,
			default: null,
		},
	},
	async mounted() {
		this.isPreloading();
		await this.getReasonsByModule();
		if (this.evolutionSpecimen == 1) {
			await this.getLotesAvailable();
		}
		this.toggleModal("modal-register-deads");
		this.isPreloading(false);
		this.arrayDeads[0].type = this.evolutionSpecimen;
	},
	computed: {
		title() {
			return (
				"Registrar muertos en " +
				(this.evolutionSpecimen === 1
					? "cintillo pata"
					: this.evolutionSpecimen === 2
					? "cintillo ala"
					: "placa")
			);
		},
		resultQuery() {
			if (this.searchQuery) {
				return this.resources.filter((item) => {
					return this.searchQuery
						.toLowerCase()
						.split(" ")
						.every((v) => item.title.toLowerCase().includes(v));
				});
			} else {
				return this.resources;
			}
		},
		stockPostura: () => (stocks, egg_lote_id) => {
			const [num] = stocks.filter((it) => it.egg_lote_id == egg_lote_id).map((it) => it.stock);
			return num;
		},
	},
	data() {
		return {
			arrayDeads: [
				{
					type: this.evolutionSpecimen,
					id: null,
					reason_id: null,
					postura: null,
					withEvidence: false,
					evidences: [],
					customFormData: [],
					error: null,
					observation: null,
				},
			],
			options: [
				{ item: 1, name: "Cintillo pata" },
				{ item: 2, name: "Cintillo ala" },
				{ item: 3, name: "Placa" },
			],
			arrayUsedCombinations: [],
			lotesAvailable: [],
			isDragging: false,
			dragCount: 0,
			files: [],
			images: [],
			circunstances: [
				{ item: 1, name: "Pelea" },
				{ item: 2, name: "Natural" },
			],
			plates: [],
			arrayBreeders: [],
			arrayReason: [],
			fileData: null,
			shouldStick: true,
			openLotesByCombination: false,
			arrayPosturas: [],
			selectedDeadIndex: null,
			selectedLote: {},
			loading: false,
			saveLoading: false,
			arrayChicks: [],
			// modalEvidencesController: false,
			loading: false,
			nextItem: 1,
			items: [
				{
					id: 1,
					plate: "A1",
				},
				{
					id: 2,
					plate: "A2",
				},
				{
					id: 3,
					plate: "A3",
				},
				{
					id: 4,
					plate: "A4",
				},
				{
					id: 5,
					plate: "A5",
				},
				{
					id: 6,
					plate: "A6",
				},
				{
					id: 7,
					plate: "A7",
				},
				{
					id: 8,
					plate: "A8",
				},
				{
					id: 9,
					plate: "A9",
				},
				{
					id: 10,
					plate: "A10",
				},
			],
			depuracion_boolean: true,
			cartsByLote: [],
			observation_depuracion: null,
			arrayStocks: [],
		};
	},
	methods: {
		...mapMutations("encasteChicks", ["TOGGLE_RELOAD_CHICKS"]),
		validateCintilloAla(dead, index) {
			let count = 0;
			this.arrayDeads.forEach((d) => {
				if (d.id === dead.id) count++;
			});
			if (count > 1) {
				this.showToast(
					"danger",
					"top-right",
					"Error",
					"AlertCircleIcon",
					"El cintillo en la ala ha sido seleccionada anteriormente"
				);
				this.arrayDeads[index].id = null;
			}
		},
		async saveDeads() {
			if (this.evolutionSpecimen == 1 && this.arrayDeads.some((d) => d.postura == null)) {
				this.showToast(
					"danger",
					"top-right",
					"Codigo de lote no seleccionado",
					"AlertCircleIcon",
					"Seleccione un cintillo con su lote a depurar"
				);
				return;
			}
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}

			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.saveLoading = true;
			this.isPreloading();
			this.arrayDeads = await setEvidencesBase64(this.arrayDeads);

			this.arrayDeads = this.arrayDeads.map((dead) => {
				return {
					...dead,
					observation: dead.observation,
				};
			});

			if (this.evolutionSpecimen == 1) {
				// Para matar o depurar contillo en pata
				const { ok } = await deadsService.saveDead(this.arrayDeads, false, this.depuracion_boolean);
				// await axios.post("/api/deads/save", { deads: JSON.stringify(deads) });
				if (ok) this.showSuccessToast("Muerte registrada correctamente!");
			} else {
				// PAra matar o depurar cintillo en ala

				let imagesArray = [];

				this.arrayDeads.forEach((it) => {
					const images = it.customFormData.map((df) => {
						return {
							...df,
							chick_id: it.id,
						};
					});
					imagesArray = [...imagesArray, ...images];
				});

				const { message } = await chicksService.deadChicksMassively({
					chicks: this.arrayDeads.map((it) => {
						return {
							id: it.id,
							observation: it.observation,
							postura: it.postura,
							reason_id: it.reason_id,
						};
					}),
					evidences: imagesArray,
					status_id: this.depuracion_boolean ? 3 : 4,
					is_masive: 0,
				});

				await this.showToast("success", "top-right", "Pollos", "InfoIcon", message);
			}

			this.saveLoading = false;
			this.$emit("hidden", true);

			this.isPreloading(false);
			this.TOGGLE_RELOAD_CHICKS({ reload: true });
		},
		async getReasonsByModule() {
			const data = await reasonsService.getByModule();
			this.arrayReason = data;
		},
		async getLotesAvailable() {
			const { data } = await axios.get("api/cintillo-pata/lotes-available");
			this.lotesAvailable = data;
			this.cartsByLote = data.map(({ lote_id }) => {
				return {
					lote_id,
					cart: [],
				};
			});
		},
		deleteDead(dead, index) {
			// console.log('dead', dead, index);
			if (this.arrayDeads.length <= 1) {
				this.$swal({
					title: "Error",
					text: "Debe haber al menos un registro",
					icon: "error",
				});
				return;
			}
			// SELECTED PSOTURA ES saber en q posicion del array de outputs esta para saber donde aumentar
			// o reponer el stock
			this.arrayDeads = this.arrayDeads.filter((it, key) => index != key);
			if (dead.id) {
				this.arrayDeads = this.arrayDeads.filter((it, key) => index != key);
				let loteArray = this.cartsByLote.find((l) => l.lote_id === dead.id.lote_id);
				loteArray.cart = loteArray.cart.filter((c) => c.postura.code !== dead.postura.code);
				this.updateStock(dead);
			}
		},
		addDead() {
			// console.log('ADD');
			let obj = {
				id: null,
				observation: null,
				circunstances: null,
				postura: null,
				evidences: [],
				customFormData: [],
				error: null,
				observation: null,
				type: this.evolutionSpecimen,
			};
			this.arrayDeads.push(obj);
		},
		// AL SELECCIONAR UNA POSTURA DEL LOTE;
		onSelectPostura(postura, index) {
			// return ;
			if (!this.arrayStocks.map((it) => it.egg_lote_id).includes(postura.egg_lote_id)) {
				this.arrayStocks.push({
					egg_lote_id: postura.egg_lote_id,
					total: postura.qty,
					stock: postura.qty,
				});

				// console.log("ADD", this.arrayStocks);
			}

			if (postura.qty == 0) return;
			this.arrayDeads[this.selectedDeadIndex].postura = postura;

			let qty = parseInt(this.arrayDeads[this.selectedDeadIndex].postura.qty);
			this.arrayDeads[this.selectedDeadIndex].postura.stock = qty;
			this.arrayDeads[this.selectedDeadIndex].postura.quantity = 1;
			// this.arrayDeads[this.selectedDeadIndex].id.outputs[index].qty = 0;

			this.openLotesByCombination = false;

			let item = this.cartsByLote.find((c) => c.lote_id == this.selectedLote.id);
			item.cart = this.arrayDeads.filter((d) => {
				if (d.id) {
					return d.id.lote_id == this.selectedLote.id;
				}
			});
			if (!this.arrayDeads.some((d) => d.postura == null)) {
				this.addDead();
			}

			const idPostura = this.arrayStocks.findIndex((it) => it.egg_lote_id == postura.egg_lote_id);
			this.arrayStocks[idPostura].stock = this.arrayStocks[idPostura].stock - 1;
		},
		async onSelectLoteCode(dead, index) {
			this.isPreloading();
			this.arrayPosturas = [];
			const { data } = await deadsService.getPosturasAvailableCpByLote({
				lote_id: dead.id.lote_id,
			});

			this.arrayPosturas = this.postura_id ? data.filter((it) => it.postura_id == this.postura_id) : data;

			this.arrayPosturas = this.arrayPosturas.map((item) => {
				return {
					...item,
					stock_total: parseInt(item.qty),
					qty: parseInt(item.qty),
					cintillos: item.cintillos ? item.cintillos : null,
					user_change_status: item.user_change_status ? JSON.parse(item.user_change_status) : null,
				};
			});
			this.isPreloading(false);

			this.selectedDeadIndex = index;
			this.selectedLote = { code: dead.id.code, id: dead.id.lote_id };
			this.openLotesByCombination = true;

			let item = this.cartsByLote.find((c) => c.lote_id == this.selectedLote.id);
			if (item) {
				this.$nextTick(() => {
					for (const { postura } of item.cart) {
						let post = this.arrayPosturas.find((p) => p.code == postura.code);
						if (post) {
							console.log({ post });
							post.qty -= postura.qty;
						}
					}
				});
			}
		},
		onUpdate($event, index, dead) {
			// TODO revisar otra vez
			this.arrayDeads[index].postura.qty = $event;
			this.updateStock(dead);
			// console.log("add", $event, index, dead, this.arrayStocks[indexStock], sumTotal);
		},
		updateStock(dead) {
			// console.log('dead', dead);
			const indexStock = this.arrayStocks.findIndex((it) => it.egg_lote_id == dead.postura.egg_lote_id);

			const sumTotal = this.arrayDeads
				.filter((it) => (it.postura ? it.postura.egg_lote_id == dead.postura.egg_lote_id : false))
				.map((it) => (it.postura ? it.postura.qty : 0))
				.reduce(function (previousValue, currentValue) {
					return previousValue + currentValue;
				}, 0);

			this.arrayStocks[indexStock].stock = this.arrayStocks[indexStock].total - sumTotal;
		},
		async hideFooterSelectCintillo() {
			console.log("close");
			this.arrayDeads = this.arrayDeads.filter((it) => (it.id && it.postura ? true : false));
			!this.postura_id &&
				!this.lote_id &&
				this.arrayDeads.push({
					type: this.evolutionSpecimen,
					id: null,
					reason_id: null,
					// circunstances: null,
					// breeder_id: null,
					postura: null,
					evidences: [],
					customFormData: [],
					error: null,
					observation: null,
				});

			await this.$refs.form.reset();
		},
		//
		showEvidenceSelector(idx) {
			this.arrayDeads[idx].withEvidence = true;
			this.$forceUpdate();
		},
		hideEvidenceSelector(idx) {
			this.arrayDeads[idx].withEvidence = false;
			this.arrayDeads[idx].evidences = [];
			// console.log();
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss">
#fixedbutton {
	position: sticky;
	right: 0;
	bottom: 0;
	float: right;
}
.number-input--controls > .number-input__input {
	text-align: center !important;
}
.input-select-observation {
	// margin-top: 3rem;
	@media screen and (min-width: 770px) {
		margin-top: 0;
	}
}
.select-cintillo {
	.vs__selected-options {
		margin-top: 0.5rem;
		margin-bottom: 0.2rem;
		// background: orange;
		display: flex !important;
		height: 21px;
		input {
			display: none !important;
		}
	}
}
.container-evidences {
	display: grid;
	grid-template-columns: 1fr;
	@media screen and (min-width: 500px) {
		grid-template-columns: 1fr 4fr;
	}
}
</style>
