<template>
	<div class="text-center">
		<feather-icon
			@click="minusOne()"
			icon="MinusCircleIcon"
			size="30"
			class="cursor-pointer"
			:class="{ 'pointer-events-none': isDisabled }"
		/>
		<b-avatar
			:variant="variantColor"
			style="font-size: 40px !important"
			class="mx-1"
			size="30"
			button
			:disabled="isDisabled"
			:class="{ 'pointer-events-none': !isDisabled }"
			><span class="font-small-4">{{ postura[principalKey] }}</span></b-avatar
		>
		<feather-icon
			@click="sumOne()"
			icon="PlusCircleIcon"
			size="30"
			class="cursor-pointer"
			:class="{ 'pointer-events-none': isDisabled }"
		/>
	</div>
</template>

<script>
export default {
	name: "ButtonsCounterInfertile",
	props: {
		postura: {
			type: Object,
			required: true,
		},
		principalKey: {
			type: String,
			required: true,
		},
		maxNumber: {
			type: Number,
		},
		minNumber: {
			type: Number,
		},
		isDisabled: {
			type: Boolean,
			required: true,
		},
		color: {
			type: Boolean,
			default: false,
		},
		isZero: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		variantColor() {
			if (
				(this.color && this.postura[this.principalKey] > 0) ||
				(this.isZero && this.postura[this.principalKey] == 0)
			) {
				return "danger";
			}
			return "primary";
		},
		// dangerZero() {
		// 	if (this.isZero && this.postura[this.principalKey] == 0) {
		// 		return "danger";
		// 	}
		// 	return "primary";
		// },
	},

	methods: {
		sumOne() {
			if (
				(this.postura.infertiles ? this.postura.infertiles : 0) +
					(this.postura.quemados ? this.postura.quemados : 0) <
					(this.postura.ingresado ? this.postura.ingresado : 0) &&
				!this.isDisabled
			) {
				// this.postura.n_nac = (this.postura.n_nac ? this.postura.n_nac : 0) - 1;
				this.postura[this.principalKey]++;
				// this.postura.nac =
				// 	(this.postura.inc ? this.postura.inc : 0) - (this.postura.n_nac ? this.postura.n_nac : 0);

				this.postura.incubando = this.postura.ingresado - (this.postura.infertiles + this.postura.quemados);
				this.$emit("update-postura");
			}
		},
		minusOne() {
			if (this.postura[this.principalKey] > this.minNumber && !this.isDisabled) {
				this.postura[this.principalKey]--;
				// this.postura.nac = (this.postura.nac ? this.postura.nac : 0) + 1;
				// this.postura.n_nac =
				// 			(this.postura.inc ? this.postura.inc : 0) - (this.postura.nac ? this.postura.nac : 0);

				this.postura.incubando = this.postura.ingresado - (this.postura.infertiles + this.postura.quemados);
				this.$emit("update-postura");
			}
		},
	},
};
</script>

<style></style>
