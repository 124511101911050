<template>
	<RevisionLotesSlot>
		<template v-slot:title>
			<h3 class="mb-0 text-white">LOTE: {{ lote.code }}</h3>
		</template>

		<template v-slot:subtitle>
			<div class="d-flex flex-column flex-md-row align-items-md-center">
				<h2 class="mb-0 text-white">ETAPA {{ lote.stage }}</h2>
				<p class="mb-0 font-weight-bolder ml-md-2">{{ lote.title }}</p>
			</div>
		</template>

		<template v-slot:table>
			<b-table-simple sticky-header class="position-relative table-revision-infertile">
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-1" v-for="(item, key) in headTable" :key="key">
							<template v-if="item.visible">
								<div class="d-flex align-items-center justify-content-center">
									<!-- <img class="icon-head-posturas" :src="item.image" alt="" /> -->
									<p class="mb-0">{{ item.text }}</p>
								</div>
							</template>
						</b-th>
					</b-tr>
				</b-thead>

				<b-tbody>
					<template>
						<b-tr class="text-center" :style="{ backgroundColor: '#f3f2f7' }">
							<b-td class="p-1"> </b-td>
							<b-td class="p-1"> TOTAL </b-td>
							<b-td class="p-1">
								{{ sumEggs(posturas, "ingresado") }}
							</b-td>
							<b-td class="p-1">
								{{ sumEggs(posturas, "infertiles") }}
							</b-td>
							<b-td class="p-1">
								{{ sumEggs(posturas, "quemados") }}
							</b-td>
							<b-td class="p-1">
								{{ sumEggs(posturas, "incubando") }}
							</b-td>
						</b-tr>
						<b-tr v-for="(postura, index) in posturas" class="text-center" :key="index">
							<b-td class="text-center"> {{ postura.madrilla }} </b-td>
							<b-td class="text-center"> {{ postura.padrillo }} </b-td>

							<b-td class="text-center"> {{ postura.ingresado }} </b-td>

							<b-td class="text-center">
								<ButtonsCounterInfertile
									:quantity="parseInt(postura.infertiles)"
									:color="true"
									:maxNumber="parseInt(postura.ingresado)"
									:minNumber="parseInt(posturasCopy[index].infertiles)"
									principalKey="infertiles"
									:postura="postura"
									:isDisabled="status_stage == 3"
								/>
							</b-td>

							<b-td class="text-center">
								<ButtonsCounterInfertile
									:quantity="parseInt(postura.infertiles)"
									:color="true"
									:maxNumber="parseInt(postura.ingresado)"
									:minNumber="parseInt(posturasCopy[index].quemados)"
									principalKey="quemados"
									:postura="postura"
									:isDisabled="status_stage == 3"
								/>
							</b-td>

							<b-td class="text-center"> {{ postura.incubando }} </b-td>
						</b-tr>
					</template>
				</b-tbody>
				<b-tfoot>
					<b-tr>
						<b-th class="p-1 text-center"> </b-th>
						<b-th class="p-1 text-center"> TOTAL </b-th>
						<b-th class="p-1 text-center">
							{{ sumEggs(posturas, "ingresado") }}
						</b-th>
						<b-th class="p-1 text-center">
							{{ sumEggs(posturas, "infertiles") }}
						</b-th>
						<b-th class="p-1 text-center">
							{{ sumEggs(posturas, "quemados") }}
						</b-th>
						<b-th class="p-1 text-center">
							{{ sumEggs(posturas, "incubando") }}
						</b-th>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
			<div>
				<b-form-textarea
					id="textarea"
					v-model="text"
					placeholder="Observaciones"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>
		</template>

		<template v-slot:btns>
			<div class="d-flex justify-content-end mt-1">
				<b-button variant="outline-primary mr-1 " @click="saveEggs(status_stage == 3 ? 3 : 4)"
					>Guardar</b-button
				>
				<b-button :disabled="status_stage == 3" variant="primary" @click="saveEggs(3)">Confirmar</b-button>
			</div>
		</template>
	</RevisionLotesSlot>
</template>

<script>
import lotesService from "@/services/lotes.service";
import { backgroundColor } from "echarts/lib/theme/dark";
import ButtonsCounterInfertile from "../../lotes/components/ButtonsCounter.vue";
import RevisionLotesSlot from "./RevisionLotesSlot.vue";

export default {
	name: "RevisionInfertile",
	props: {
		lote: {
			type: Object,
		},
	},
	components: {
		ButtonsCounterInfertile,
		RevisionLotesSlot,
	},
	computed: {
		sumEggs: () => (posturas, key) => {
			return posturas.reduce(function (previousValue, currentValue) {
				return parseInt(previousValue + parseInt(currentValue[key]));
			}, 0);
		},
	},
	data() {
		return {
			headTable: [
				{ text: "MADRILLA", value: "madrilla", visible: true },
				{ text: "PADRILLO", value: "padrillo", visible: true },
				{
					text: "INGRESADOS",
					value: "ing",
					image: "/lotes/lote-grid-2.svg",
					visible: true,
				},
				{
					text: "INFERTILES",
					value: "inf",
					image: "/lotes/lote-grid-3.svg",
					visible: true,
				},
				{
					text: "QUEMADOS",
					value: "que",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "INCUBANDO",
					value: "inc",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
			],
			posturas: [],
			text: "",
			status_stage: 1,
			posturasCopy: [],
			indexType: 0,
			item: {},
			winScreen: screen.width,
		};
	},
	async mounted() {
		await this.getLotes();
	},
	methods: {
		async getLotes() {
			this.isPreloading();
			const resp = await lotesService.getStagesRevisionInfertile({
				stageLoteId: this.lote.stage,
				loteId: this.lote.id,
				status_stage_lote_id: this.lote.status_stage_lote_id,
			});
			this.posturas = resp.data.map((item) => {
				return {
					...item,
					incubando: parseInt(item.incubando),
					infertiles: parseInt(item.infertiles),
					quemados: parseInt(item.quemados),
					ingresado: parseInt(item.ingresado),
				};
			});
			this.posturasCopy = JSON.parse(JSON.stringify(resp.prev));
			this.text = this.posturas[0].observation;
			this.status_stage = this.posturas[0].status_stage;
			// this.posturas = response;
			// console.log("resp", resp);
			this.isPreloading(false);
		},
		updateTotal(q, balance) {
			if (this.indexType == 0) {
				this.item.infertiles = q;
			} else if (this.indexType == 1) {
				this.item.quemados = q;
			}
			this.item.incubando = balance;
		},
		async saveEggs(status) {
			this.isPreloading();
			this.differenceQuantity();
			const params = {
				posturas: this.posturas,
				stageLoteId: this.lote.stage,
				loteId: this.lote.id,
				status: status,
				observation: this.text,
			};
			const resp = await lotesService.saveStagesRevisionInfertile(params);
			// console.log('dfdf', this.posturas[0].inf, this.posturasCopy[0].inf);
			//   const resp = await lotesService.saveStagesRevisionInfertile({
			//     // posturas: this.posturas.map((postura, index) => {
			//     //   // return {
			//     //   //     ...postura,
			//     //   //     ing: postura.in - this.posturasCopy[index].inf,
			//     //   //     inf: postura.inf - this.posturasCopy[index].inf ,
			//     //   //     que: postura.que - this.posturasCopy[index].que
			//     //   // }
			//     // }),
			//     // text: this.text,
			//     // status,
			//     // loteId: this.lote.id,
			//   });
			await this.$emit("refresh");
			await this.getLotes();
			this.isPreloading(false);
		},

		differenceQuantity() {
			this.posturas = this.posturas.map((item, index) => {
				return {
					...item,
					infertiles: item.infertiles - this.posturasCopy[index].infertiles,
					quemados: item.quemados - this.posturasCopy[index].quemados,
				};
			});
		},
	},
};
</script>

<style lang="scss">
.icon-head-posturas {
	margin-right: 0.2rem;
}
.table-revision-infertile {
	min-height: 54vh;
}
</style>
