<template>
	<div class="text-center d-flex">
		<feather-icon
			@click="minusOne()"
			icon="MinusCircleIcon"
			size="30"
			class="cursor-pointer"
			:class="{ 'pointer-events-none': isDisabled }"
		/>
		<div class="container-number" :class="[variantColor == 'primary' ? 'bg-primary' : 'bg-danger']">
			<p class="mb-0 font-small-4 d-flex justify-content-center align-items-center">{{
				postura[principalKey]
			}}</p>
		</div>

		<feather-icon
			@click="sumOne()"
			icon="PlusCircleIcon"
			size="30"
			class="cursor-pointer"
			:class="{ 'pointer-events-none': isDisabled }"
		/>
	</div>
</template>

<script>
export default {
	name: "ButtonsCounter",
	props: {
		postura: {
			type: Object,
			required: true,
		},
		principalKey: {
			type: String,
			required: true,
		},
		secondaryKey: {
			type: String,
			required: true,
		},
		maxNumber: {
			type: Number,
		},
		isDisabled: {
			type: Boolean,
			required: true,
		},
		color: {
			type: Boolean,
			default: false,
		},
		isZero: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		variantColor() {
			if (
				(this.color && this.postura[this.principalKey] > 0) ||
				(this.isZero && this.postura[this.principalKey] == 0)
			) {
				return "primary";
			}
			return "danger";
		},
	},
	methods: {
		sumOne() {
			if (this.postura[this.principalKey] < this.maxNumber && !this.isDisabled) {
				console.log("simon");
				// this.postura[this.principalKey] = (this.postura[this.principalKey] ? this.postura[this.principalKey] : 0) - 1;
				this.postura[this.principalKey]++;
				this.postura[this.secondaryKey]--;

				// (this.postura.inc ? this.postura.inc : 0) - (this.postura[this.principalKey] ? this.postura[this.principalKey] : 0);
				// this.$emit("update-postura");
			}
		},
		minusOne() {
			if (this.postura[this.principalKey] * 1 > 0 && !this.isDisabled) {
				this.postura[this.principalKey]--;

				this.postura[this.secondaryKey]++;
			}
		},
	},
};
</script>

<style lang="scss">
.container-number {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	margin: 0 0.1rem;
	p {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bolder !important;
	}
}
</style>
