<template>
	<div>
		<!-- {{ status }} -->
		<div v-if="status == 2" class="container-btns-tabs-postura-in-progress">
			<div
				@click="tabProcessingActive = 0"
				class="btn-tab-posturas-in-progress"
				:class="[tabProcessingActive == 0 ? 'btn-tab-posturas-in-progress-active' : '']"
			>
				<font-awesome-icon icon="fa-solid fa-bars" size="2xl" />
			</div>

			<div
				@click="tabProcessingActive = 1"
				class="btn-tab-posturas-in-progress"
				:class="[tabProcessingActive == 1 ? 'btn-tab-posturas-in-progress-active' : '']"
			>
				<font-awesome-icon icon="fa-solid fa-table-list" size="2xl" />
			</div>
		</div>

		<PosturasListByStatus
			v-if="status != 2 && start_date && end_date"
			:statusFromModal="status"
			@refresh-counters="$emit('initCounters')"
		/>

		<b-tabs nav-wrapper-class="card pr-1 pl-1 d-none" v-model="tabProcessingActive" lazy v-else>
			<b-tab>
				<PosturasListByStatus
					v-if="start_date && end_date"
					:statusFromModal="status"
					@refresh-counters="$emit('initCounters')"
				/>
			</b-tab>
			<b-tab>
				<ComponentTreePosturas v-if="tabProcessingActive == 1" />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import PosturasListByStatus from "../PosturasListByStatus.vue";
import ComponentTreePosturas from "../TreePosturas/ComponentTreePosturas.vue";
import { mapState } from "vuex";

export default {
	name: "PosturasStatusContainer",
	components: {
		PosturasListByStatus,
		ComponentTreePosturas,
	},
	data() {
		return {
			tabProcessingActive: 0,
		};
	},

	computed: {
		...mapState("encastePosturas", ["start_date", "end_date"]),
		status() {
			return this.$route.meta.status;
		},
	},
	methods: {
		initCounters() {
			// this.$root.$emit('reloader-counters-posturas');
		},
	},
};
</script>
