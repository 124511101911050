<template>
	<RevisionLotesSlot>
		<template v-slot:title>
			<div class="container-title bg-primary rounded px-2 py-75 mb-1">
				<h3 class="mb-0 text-white">LOTE: {{ lote.code }}</h3>
			</div>
			<h3 class="mb-0 text-white">LOTE: {{ lote.code }}</h3>
		</template>

		<template v-slot:subtitle>
			<div class="d-flex flex-column flex-md-row align-items-md-center">
				<h2 class="mb-0 text-white">ETAPA {{ lote.stage }}</h2>
				<p class="mb-0 font-weight-bolder ml-md-2">{{ lote.title }}</p>
			</div>
		</template>

		<template v-slot:table>
			<b-table-simple sticky-header class="position-relative" :style="{ minHeight: '54vh' }">
				<b-thead>
					<b-tr class="text-center">
						<template>
							<b-th class="p-1" v-for="(item, key) in headTable" :key="key">
								<div class="d-flex align-items-center justify-content-center">
									<!-- <img class="icon-head-posturas" :src="item.image" alt="" /> -->
									<p class="mb-0">{{ item.text }}</p>
								</div>
							</b-th>
						</template>
					</b-tr>
				</b-thead>

				<b-tbody>
					<template>
						<b-tr :style="{ backgroundColor: '#f3f2f7' }" class="text-center">
							<b-th class="p-1"></b-th>
							<b-th class="p-1">TOTAL</b-th>

							<b-th class="p-1">
								{{ sumEggs(posturas, "nac") }}
							</b-th>
							<b-th class="p-1"></b-th>
							<b-th class="p-1"></b-th>
							<b-th class="p-1 text-center">
								<div class="d-flex">
									<div class="m-auto">
										<b-form-checkbox
											:disabled="status_stage == 3"
											@input="checkLoteFunction"
											v-model="checkLote"
											name="checkbox-1"
										/>
									</div>
								</div>
							</b-th>
						</b-tr>
						<b-tr v-for="(postura, index) in posturas" class="text-center" :key="index">
							<b-td class="text-center">{{ postura.madrilla.alias }} / {{ postura.madrilla.plate }}</b-td>
							<b-td class="text-center" :style="{ padding: 0, margin: 0 }">
								{{ postura.padrillo.alias }} / {{ postura.padrillo.plate }}
							</b-td>

							<b-td class="text-center">
								{{ postura.nac }}
							</b-td>

							<b-td class="text-center">
								<CombChickenlegsCintillos
									:postura="{
										id: postura.id,
										chickenleg_wich_leg: postura.cintillos.wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: postura.nac,
									}"
									:chickenleg_wich_leg="1"
								/>
							</b-td>

							<b-td class="text-center">
								<CombChickenlegsCintillos
									:postura="{
										id: postura.id,
										chickenleg_wich_leg: postura.cintillos.wich_leg,
										chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
										cintillo_color: postura.cintillos.hex_1,
										cintillo_description: postura.cintillos.description_1,
										second_color_color: postura.cintillos.hex_2,
										second_color_description: postura.cintillos.description_2,
										total_minus_infertile_eggs: postura.nac,
									}"
									:chickenleg_wich_leg="2"
								/>
							</b-td>

							<b-td class="text-center">
								<div class="d-flex">
									<div class="m-auto">
										<b-form-checkbox :disabled="true" v-model="postura.check"></b-form-checkbox>
									</div>
								</div>
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
				<b-tfoot>
					<b-tr>
						<b-th class="p-1"></b-th>
						<b-th class="p-1 text-center">TOTAL</b-th>
						<b-th class="p-1 text-center">
							{{ sumEggs(posturas, "nac") }}
						</b-th>
						<b-th class="p-1 text-center"></b-th>

						<b-th class="p-1 text-center"></b-th>
						<b-th colspan="3" class="p-1 text-center"></b-th>
					</b-tr>
				</b-tfoot>
			</b-table-simple>
			<div>
				<b-form-textarea
					id="textarea"
					v-model="text"
					placeholder="Observaciones"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>
		</template>

		<template v-slot:btns>
			<div class="d-flex justify-content-end mt-1">
				<b-button
					:disabled="!checkLote"
					variant="outline-primary mr-1 "
					@click="saveVaccinate(status_stage == 3 ? 3 : 4)"
				>
					Guardar
				</b-button>
				<!-- <b-button
					:disabled="status_stage == 3 || !checkLote"
					v-if="status_stage != 3"
					variant="primary"
					@click="saveVaccinate(3)"
					>Confirmar</b-button
				> -->
			</div>
		</template>
	</RevisionLotesSlot>
</template>

<script>
import lotesService from "@/services/lotes.service"
import ButtonsCounter from "@/components/commons/utilities/ButtonsCounter.vue"
import CombChickenlegsCintillos from "../components/CombChickenlegsCintillos.vue"
import RevisionLotesSlot from "./RevisionLotesSlot.vue"

export default {
	name: "RevisionVaccinate",
	props: {
		lote: {
			type: Object,
		},
	},
	components: {
		CombChickenlegsCintillos,
		ButtonsCounter,
		RevisionLotesSlot,
	},
	computed: {
		sumEggs: () => (posturas, key) => {
			return posturas.reduce(function (previousValue, currentValue) {
				return previousValue + currentValue[key] * 1
			}, 0)
		},
	},
	data() {
		return {
			headTable: [
				{ text: "MADRILLA", value: "madrilla", visible: true },
				{ text: "PADRILLO", value: "padrillo", visible: true },
				{
					text: "NACIERON",
					value: "inf",
					image: "/lotes/lote-grid-3.svg",
					visible: true,
				},
				{
					text: "PATA IZQ",
					value: "inc",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "PATA DER",
					value: "inc",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "VACUNACIÓN",
					value: "vac",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
			],
			posturas: [],
			text: "",
			status_stage: 1,
			date_dob: new Date().toISOString().substr(0, 10),
			checkLote: false,
		}
	},
	async mounted() {
		await this.getLotes()
	},
	methods: {
		async getLotes() {
			this.isPreloading()
			this.posturas = await lotesService.getStagesRevisionNacimiento({
				// stageLoteId: this.lote.stage,
				// stageCode: 5,
				// loteId: this.lote.id,
				// status_stage_lote_id: this.lote.status_stage_lote_id,
				stageLoteId: 4,
				loteId: this.lote.id,
				status_stage_lote_id: this.lote.status_stage_lote_id,
				stageCode: 5,
			})
			this.posturas = this.posturas.map((item) => {
				return {
					...item,
					check: false,
				}
			})
			console.log(this.posturas)
			if (this.posturas[0]) this.text = this.posturas[0].observation
			this.status_stage = this.lote.status_stage_lote_id
			if (this.status_stage == 3) {
				this.checkLote = true
			}
			this.isPreloading(false)
			// this.posturas = response;
		},
		checkLoteFunction() {
			this.posturas = this.posturas.map((item) => {
				return {
					...item,
					check: this.checkLote,
				}
			})
		},
		async saveVaccinate(status) {
			this.isPreloading()
			const resp = await lotesService.saveVaccinate({
				loteId: this.lote.id,
				text: this.text,
			})
			this.$emit("refresh")
			await this.getLotes()
			this.isPreloading(false)
		},
	},
}
</script>

<style lang="scss">
.icon-head-posturas {
	margin-right: 0.2rem;
}

.container-cintillos {
	margin-top: 0.5rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;

	.cintillo {
		height: 30px;
		width: 20px;
		background: red;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 64%, 0 100%);
		position: relative;
		margin-right: 0.5rem;

		:last-child {
			margin-right: 0;
		}

		span {
			font-weight: bold;
			position: absolute;
			left: 50%;
			translate: -50%;
			color: #fff;
		}
	}
}
</style>
