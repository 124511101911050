var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","no-close-on-backdrop":"","size":"sm","title":_vm.title},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.update}},[_vm._v("Actualizar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',{attrs:{"label":"Estado"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"label":"text","reduce":function (opt) { return opt.value; },"options":_vm.statusOpts,"selectable":function (opt) { return opt.selectable; }},on:{"input":_vm.clearForm},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})]}}])})],1),([11, 5, 4, 2].includes(_vm.form.status))?_c('b-form-group',{attrs:{"label":"Cliente o beneficiario"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"label":"text","reduce":function (opt) { return opt.value; },"options":_vm.clientOpts},model:{value:(_vm.form.client),callback:function ($$v) {_vm.$set(_vm.form, "client", $$v)},expression:"form.client"}})]}}],null,false,2944944425)})],1):_vm._e(),(_vm.form.is_reason == 1)?_c('b-form-group',{attrs:{"label":"Motivo"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"label":"text","reduce":function (opt) { return opt.value; },"options":_vm.diseaseOpts},model:{value:(_vm.form.disease),callback:function ($$v) {_vm.$set(_vm.form, "disease", $$v)},expression:"form.disease"}})]}}],null,false,1234742345)})],1):_vm._e(),([9, 10].includes(_vm.form.status))?_c('b-form-group',{attrs:{"label":"Peso"}},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger ': errors[0] },model:{value:(_vm.form.weight),callback:function ($$v) {_vm.$set(_vm.form, "weight", $$v)},expression:"form.weight"}})]}}],null,false,2621139013)})],1),_c('b-col',[_c('b-form-input',{attrs:{"value":_vm.weightToKilo,"disabled":""}})],1)],1)],1):_vm._e(),([3].includes(_vm.form.status))?_c('b-form-group',{attrs:{"label":"Evidencias"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{class:{ 'border-danger ': errors[0] },attrs:{"multiple":""},model:{value:(_vm.form.evidence),callback:function ($$v) {_vm.$set(_vm.form, "evidence", $$v)},expression:"form.evidence"}})]}}],null,false,2618285340)})],1):_vm._e(),([2].includes(_vm.form.status))?_c('b-form-group',{attrs:{"label":"Precio"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{class:{ 'border-danger ': errors[0] },attrs:{"type":"number"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)]}}],null,false,884819144)})],1):_vm._e(),([14].includes(_vm.form.status))?_c('b-form-group',{attrs:{"label":"Dias de descanso"}},[_c('v-select',{attrs:{"getOptionLabel":function (o) { return String(o); },"reduce":function (opt) { return opt; },"options":[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]},model:{value:(_vm.form.restingDays),callback:function ($$v) {_vm.$set(_vm.form, "restingDays", $$v)},expression:"form.restingDays"}})],1):_vm._e(),(_vm.form.status != 3)?_c('b-form-group',{attrs:{"label":"Descripcion"}},[_c('ValidationProvider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:{ 'border-danger': errors[0] },model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,false,1752889983)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }