<template>
	<b-modal centered no-close-on-backdrop size="sm" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-form-group>
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							v-model="padrillo"
							label="alias"
							:options="padrilloOpts"
							:clearable="false"
							:reduce="(opt) => opt.id"
							:filter="fuseSearch"
							:class="{ 'border-danger rounded': errors[0] }"
						>
							<template #selected-option="{ alias, plate }">
								<span v-if="alias" v-text="`${alias} / (${plate})`"></span>
								<span v-else v-text="`(${plate})`"></span>
							</template>
							<template v-slot:option="opt">
								<span>
									{{ opt.alias || "---" }} / ({{ opt.plate || "---" }})
									<span v-if="!opt.enabled">- Deshabilitado</span>
								</span>
							</template>
						</v-select>
					</ValidationProvider>
				</b-form-group>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="primary" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import posturaService from "@/services/posturas.service"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		//
		padrilloOpts: [],
		padrillo: undefined,
	}),
	computed: {
		title() {
			return `Cambiar Padrillo / Postura: ${this.info.postura ? this.info.postura.code : "---"}`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.padrillo = undefined
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await axios.get("api/postura/active-padrillos")
			this.padrilloOpts = data.filter((p) => p.id != this.info.padrillo.id)
			this.isLoading = false
		},
		fuseSearch(options, search) {
			const fuse = new Fuse(options, {
				keys: ["plate", "alias"],
				shouldSort: true,
			})
			return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isLoading = true
			await posturaService.changePendingPosturaPadrillo({
				postura_id: this.info.postura.id,
				new_padrillo_id: this.padrillo,
			})
			this.isLoading = false
			this.showToast("success", "top-right", "Posturas", "SuccessIcon", "Se guardaron los cambios")
			this.$emit("refresh")
			this.handleHidden()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getOpts()
			}
		},
	},
}
</script>
