<template>
	<div>
		<div>
			<b-table-simple sticky-header class="position-relative">
				<b-thead>
					<b-tr class="text-center">
						<template>
							<b-th class="p-1"> DESCRIPCION </b-th>
							<b-th class="p-1"> USUARIO </b-th>
							<b-th class="p-1"> FECHA </b-th>
						</template>
					</b-tr>
				</b-thead>

				<b-tbody>
					<template>
						<b-tr v-for="(data, index) in tracking" class="text-center" :key="index">
							<b-td class="text-center">
								{{ data.description }}
							</b-td>

							<b-td class="text-center">
								{{ data.user }}
							</b-td>

							<b-td class="text-center">
								{{ data.updated_at | myGlobalDayShort }}
							</b-td>
						</b-tr>
					</template>
				</b-tbody>
			</b-table-simple>
		</div>
	</div>
</template>

<script>
import lotesService from "@/services/lotes.service";

export default {
	name: "TrackingStagesLotes",
	props: {
		lote: Object,
		required: true,
	},
	data() {
		return {
			tracking: [],
		};
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			const resp = await lotesService.getTrackingStageByLote({
				lote_id: this.lote.lote_id,
			});
			this.tracking = resp;
		},
	},
};
</script>
