<template>
	<b-modal centered no-close-on-backdrop hide-footer :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<h2 class="text-center mb-2">
				Total
				<b-badge variant="primary">
					{{ info.deads }}
				</b-badge>
			</h2>
			<b-tabs align="center" v-model="tab">
				<b-tab>
					<template #title>
						Ejemplares
						<b-badge class="ml-05" :variant="counters.specimens > 0 ? 'danger' : 'secondary'">
							{{ counters.specimens }}
						</b-badge>
					</template>
				</b-tab>
				<b-tab>
					<template #title>
						Cintillo Ala
						<b-badge class="ml-05" :variant="counters.alas > 0 ? 'danger' : 'secondary'">
							{{ counters.alas }}
						</b-badge>
					</template>
				</b-tab>
				<b-tab>
					<template #title>
						Cintillo Pata
						<b-badge class="ml-05" :variant="counters.patas > 0 ? 'danger' : 'secondary'">
							{{ counters.patas }}
						</b-badge>
					</template>
				</b-tab>
			</b-tabs>
			<hr />
			<b-table-simple class="text-center" sticky-header="60vh">
				<b-thead>
					<b-tr>
						<template v-if="tab == 0">
							<b-th>Ejemplar</b-th>
							<b-th>Razon</b-th>
							<b-th>Fecha</b-th>
						</template>
						<template v-else-if="tab == 1">
							<b-th>Cintillo</b-th>
							<b-th>Razon</b-th>
							<b-th>Fecha</b-th>
						</template>
						<template v-else-if="tab == 2">
							<b-th>Cantidad</b-th>
							<b-th>Razon</b-th>
							<b-th>Fecha</b-th>
						</template>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<template v-if="tab == 0">
							<b-td><SpecimenPlate :specimen="row" /></b-td>
							<b-td>
								{{ row.reason }}
							</b-td>
							<b-td>
								<span v-if="row.dead_date">{{ row.dead_date | myGlobalDayShort }}</span>
								<span v-else>---</span>
							</b-td>
						</template>
						<template v-else-if="tab == 1">
							<b-td>{{ row.correlative }}</b-td>
							<b-td>{{ row.reason }}</b-td>
							<b-td>
								<span v-if="row.date_of_dead">{{ row.date_of_dead | myGlobalDayShort }}</span>
								<span v-else>---</span>
							</b-td>
						</template>
						<template v-else-if="tab == 2">
							<b-td>{{ row.quantity }}</b-td>
							<b-td>{{ row.reason }}</b-td>
							<b-td>
								<span v-if="row.dead_date">{{ row.dead_date | myGlobalDayShort }}</span>
								<span v-else>---</span>
							</b-td>
						</template>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="3">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import posturaService from "@/services/posturas.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: { show: Boolean, info: Object },
	data: () => ({
		isActive: false,
		isLoading: false,
		//
		tab: 0,
		specimens: [],
		alas: [],
		patas: [],
		counters: { patas: 0, alas: 0, specimens: 0 },
	}),
	computed: {
		title() {
			return `Hijos Muertos / Postura: ${this.info.code || "---"}`
		},
		rows() {
			switch (this.tab) {
				case 0:
					return this.specimens
				case 1:
					return this.alas
				case 2:
					return this.patas
			}
			return []
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.tab = 0
			this.alas = []
			this.patas = []
			this.specimens = []
			this.counters = { patas: 0, alas: 0, specimens: 0 }
			this.$emit("closing")
		},
		async getData() {
			this.isLoading = true
			const data = await posturaService.getPosturaChildsDead({ postura_id: this.info.postura_id })
			this.alas = data.alas
			this.specimens = data.specimens
			// cuando hay alas que aun no tienen correlativo asignado, rellenar el array
			const alasDiff = data.counters.alas - data.alas.length
			for (let i = 0; i < alasDiff; i++) {
				this.alas.push({ correlative: "CORRELATIVO SIN ASIGNAR" })
			}
			this.patas = data.cp_deads
			this.counters = { patas: data.counters.patas, alas: data.counters.alas, specimens: data.counters.specimens }
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getData()
			}
		},
	},
}
</script>
