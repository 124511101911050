<template>
	<div>
		<!-- arrayTypeDead -->

		<b-form-group label="Tipo de muerte:">
			<!-- {{ typeDead }} -->
			<v-select
				v-model="typeDead"
				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
				:options="arrayTypeDead"
				:clearable="false"
				label="name"
				:reduce="(option) => option.id"
				placeholder="Seleccionar la razon"
			/>
		</b-form-group>

		<b-form-group v-if="typeDead == 1" label="Razon:">
			<ValidationObserver ref="form">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-select
						v-model="reason_id"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="arrayReason"
						:clearable="false"
						label="reason"
						:reduce="(option) => option.id"
						:class="{ 'border-danger rounded': errors[0] }"
						placeholder="Seleccionar la razon"
					>
						<template v-slot:option="option">
							<div class="d-flex justify-content-between align-items-center">
								<span :style="`margin-left: ${option.parent_id ? '10' : '0'}px`"
									>{{ option.reason }}
								</span>
								<span v-if="option.parent_id"> Sub-motivo de {{ option.parent_reason }} </span>
							</div>
						</template>
					</v-select>
					<span v-if="errors[0]" class="text-danger">{{ errors[0] }}</span>
				</ValidationProvider>
			</ValidationObserver>
		</b-form-group>

		<b-form-group v-else label="Onservacion (opcional):">
			<!--  -->
			<b-form-textarea v-model="observation" placeholder="Agregar observacion"></b-form-textarea>
		</b-form-group>

		<b-form-group label="Muertos">
			<vue-number-input
				v-model="quantity"
				:min="1"
				:max="postura.vivos"
				inline
				controls
				style="max-width: 150px; margin-top: 5px"
				class="text-center"
				@change=""
			/>
		</b-form-group>

		<b-form-group label="Evidencias">
			<DragAndDropImages
				v-model="evidences"
				:filesArray="evidences"
				:containerDescription="`Seleccionar evidencia`"
				containerPadding="25px"
			/>
		</b-form-group>

		<div class="d-flex justify-content-end">
			<button class="btn btn-primary" @click="saveDeadsCP">Guardar</button>
		</div>
	</div>
</template>

<script>
import DragAndDropImages from "@/components/commons/drag-and-drop/DragAndDropImages.vue";
import { setEvidencesBase64 } from "@/helpers/functions";
import deadsService from "@/services/deads.service";
import reasonsService from "@/services/reasons.service";
import CombChickenlegsCintillos from "../components/CombChickenlegsCintillos.vue";

export default {
	name: "DeadsCPLotes",
	components: {
		CombChickenlegsCintillos,
		DragAndDropImages,
	},
	props: {
		postura: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			arrayTypeDead: [
				{
					id: 1,
					name: "Muerto",
				},
				{
					id: 2,
					name: "Depurado",
				},
			],
			arrayReason: [],
			arrayDeads: [
				{
					type: 1,
					id: null,
					reason_id: null,
					// circunstances: null,
					// breeder_id: null,
					postura: null,
					evidences: [],
					customFormData: [],
					error: null,
					observation: null,
					egg_lote_id: null,
					cp_id: null,
					to_dead: true,
					quantity: 1,
				},
			],
			reason_id: null,
			evidences: [],
			quantity: null,
			typeDead: 1,
			observation: null,
		};
	},
	async mounted() {
		console.log("init");
		await this.getReasonsByModule();
	},
	methods: {
		async saveDeadsCP() {
			if (this.typeDead == 1 && !(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}
			this.isPreloading();
			this.arrayDeads[0].reason_id = this.reason_id;
			this.arrayDeads[0].evidences = this.evidences;
            this.arrayDeads[0].observation = this.observation;
			this.arrayDeads[0].postura = {
				quantity: this.quantity,
				egg_lote_id: this.postura.egg_lote_id,
				cintillos: null,
				cp_id: this.postura.cintillos.cp_id,
			};
			this.arrayDeads = await setEvidencesBase64(this.arrayDeads);
			const { ok, error } = await deadsService.saveDead(this.arrayDeads, true, this.typeDead == 1 ? true : false);
			this.isPreloading(false);

			this.$emit("hidden", true);
		},
		async getReasonsByModule() {
			const data = await reasonsService.getByModule();
			this.arrayReason = data;
		},
	},
};
</script>
